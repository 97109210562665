<template>
    <v-card>
        <v-card-text class="body-1 font-weight-medium">
            <div>Please confirm the amount of ${{ amount }}.</div>
            <div>blah.. ....</div>
            <div>blah..</div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default" @click="goBack">Back</v-btn>
            <v-btn color="success" @click="confirmPayment">Confirm</v-btn>
        </v-card-actions>
        <v-card-text v-if="paymentResponse" class=""
            ><pre>{{ paymentResponse | pretty }}</pre></v-card-text
        >
    </v-card></template
>
<script>
// import moment from "moment";
export default {
    props: {
        amount: String,
        confirmPayment: Function,
        goBack: Function,
        paymentResponse: String
    },
    pretty: function(value) {
        if (!value) return "";

        return JSON.stringify(JSON.parse(value), null, 2);
    },
    data() {
        return {};
    }
};
</script>
